import { render, staticRenderFns } from "./admin_overview.vue?vue&type=template&id=31756986&scoped=true&"
import script from "./admin_overview.vue?vue&type=script&lang=js&"
export * from "./admin_overview.vue?vue&type=script&lang=js&"
import style0 from "./admin_overview.vue?vue&type=style&index=0&id=31756986&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31756986",
  null
  
)

export default component.exports